@import "../assets/fonts/Gilroy/stylesheet.css";
@import "../assets/fonts/Montserrat/stylesheet.css";
@import "../assets/fonts/Oswald/stylesheet.css";
@import "../assets/fonts/GillSans/stylesheet.css";
@import "../assets/fonts/APEXMK3//fonts.css";

@import "~antd/dist/antd.css";

@import "./calendar";

.ant-spin-dot-item {
  background-color: white;
}

.ant-modal {
  top: 85px;
}

.ant-modal-close-x {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.slick-slider {
  width: 100%;
  flex-grow: 1;
}

.slick-list {
  height: 100%
}

.slick-track {
  display: flex;
  height: 100%;
}
.slick-track .slick-slide {
  display: flex;
  height: 100%;

  & > div {
    height: 100%;
  }
}

.ant-modal-header {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.ant-modal-content {
  border-radius: 7px;
}

.momentoModal {
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  max-width: 100vw;

  & > .ant-modal-content {
    height: 100%;
    background: grey;
    border-radius: 7px;


    & > .ant-modal-body  {
      height: 100%;
      padding: 0px;
    }

    & > .ant-modal-close {
      color: white;
      background: #1A1919;
      border-bottom-left-radius: 10px;
    }
  }
}